<template>
  <div class="section section__lk">
    <div class="section__wrapper section__subsection-wrapper">
      <div class="d-flex justify-content-between">
        <div class="title-with-icon mb-24">
          <div class="title-with-icon__icon title-with-icon__icon_order"></div>
          <div class="title-with-icon__title">Заявка на семинар</div>
        </div>
      </div>
      <template v-if="event">
        <div class="section__lk-wrapper">
          <div class="section__lk-order-info">
            <div class="section__lk-order-info__row">
              <div class="section__lk-order-info__column">
                <div class="section__lk-order-info__category">{{ event.program.title }}</div>
                <div class="section__lk-order-info__title">{{ event.title }}</div>
                <div class="d-block d-md-none" v-if="event.tutors_contact">
                  <div class="section__lk-order-info__contact d-block d-md-none">
                    <h2>Контактное лицо</h2>
                    <div v-for="contact in event.tutors_contact" :key="contact.id">
                      <div class="section__lk-order-info__person">{{ contact.fullname }}</div>
                      <div class="section__lk-order-info__phone"><a :href="'tel:' + contact.phone">{{ contact.phone }}</a></div>
                      <div class="section__lk-order-info__mail"><a :href="'mailto:' + contact.email">{{ contact.email }}</a></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section__lk-order-info__column">
                <div class="section__lk-order-info__card container-shadow fill-white">
                  <div class="d-flex p-24">
                    <div class="section__lk-order-info__data d-flex flex-column">
                      <div class="section__lk-order-info__date">{{ event.date.start | formatDate('DD') }}</div>
                      <div class="section__lk-order-info__month">{{ event.date.start | formatDate('M') }} {{ event.date.start | formatDate('YYYY') }}</div>
                      <div class="section__lk-order-info__time">{{ event.date.start | formatDate('HH:mm') }}-{{ event.date.end | formatDate('HH:mm') }}</div>
                      <div class="section__lk-order-info__location" v-if="event.city">{{ event.city.city_name_ru }}</div>
                    </div>
                    <div class="section__lk-order-info__image" v-if="event.cover">
                      <img class="object-fit-cover" :src="event.cover['400x']"/>
                    </div>
                  </div>
                  <div class="section__lk-order-info__address fill-grey p-24">
                    {{ event.location }}
                  </div>
                  <div class="section__lk-order-info__coach p-24">
                    <div class="content_blue_light">Тренер</div>
                    <div v-for="tutor in event.tutors" :key="tutor.id">{{ tutor.fullname }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="section__lk-order-info__contact d-md-block d-none">
              <h2>Контактное лицо</h2>
              <div v-for="contact in event.tutors_contact" :key="contact.id">
                <div class="section__lk-order-info__person">{{ contact.fullname }}</div>
                <div class="section__lk-order-info__phone"><a :href="'tel:' + contact.phone">{{ contact.phone }}</a></div>
                <div class="section__lk-order-info__mail"><a :href="'mailto:' + contact.email">{{ contact.email }}</a></div>
              </div>
            </div>

            <div class="section__lk-order-info__security d-none d-md-block">
              <h2>Конфиденциальность</h2>
              <p><a href="#" class="content_red">Заполнение</a> полей необходимо для проверки Ваших данных
                администраторами<sup>1</sup> для одобрения Вашего
                участия. Школа экспорта обеспечивает конфиденциальность персональных данных заявителя.</p>
              <p class="section__lk-order-info__notice content_14"><sup>1</sup> Приказ Министерства образования и науки
                Российской Федерации от
                01.07.2013 №499 “Об утверждении Порядка организации и осуществления образовательной деятельности по
                дополнительным профессиональным программам”.</p>
            </div>
          </div>
          <router-view />
        </div>
        <div class="section__lk-order-info__security d-block d-md-none">
          <h2>Конфиденциальность</h2>
          <p><a href="#" class="content_red">Заполнение</a> полей необходимо для проверки Ваших данных
            администраторами<sup>1</sup> для одобрения Вашего
            участия. Школа экспорта обеспечивает конфиденциальность персональных данных заявителя.</p>
          <p class="section__lk-order-info__notice content_14"><sup>1</sup> Приказ Министерства образования и науки
            Российской Федерации от
            01.07.2013 №499 “Об утверждении Порядка организации и осуществления образовательной деятельности по
            дополнительным профессиональным программам”.</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "Event",
  data() {
    return {
      event: null
    }
  },
  async mounted() {
    this.event = (await ApiService.get('events/' + this.$route.params.id)).data
  }
}
</script>

<style scoped>

</style>